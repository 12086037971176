._1bwj .container {
    display: flex;
    flex-wrap: wrap;
    gap: 7px;
}

._1bwj .container button {
    all: unset;
    background-color: white;
    border: 2px solid rgb(151, 96, 151);
    padding: 4px 10px;
    font-size: 12px;
    border-radius: 15px;
    cursor: pointer;
}

._1bwj .container svg {
    width: 28px;
    height: 28px;
}

._6ftp {
    display: flex;
    flex-direction: column;
}

._6ftp.sent {
    align-items: flex-end;
}

._6ftp .container {
    position: relative;
    width: 210px;
    height: 210px;
    border: 2px solid rgb(142, 190, 207);
    background-color: white;
    border-radius: 15px;
    border-top-left-radius: 0px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    cursor: pointer;
}

._6ftp.sent .container {
    border-top-left-radius: 15px;
    border-top-right-radius: 0px;
}

._6ftp .container:hover img {
    opacity: 0.8;
}

._6ftp .devilkin-gem {
    position: absolute;
    top: 80px;
    left: 80px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgb(152, 165, 185);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.8;
    visibility: hidden;
}

._6ftp .devilkin-gem svg {
    width: 20px;
    height: 20px;
    color: white;
}

._6ftp .container:hover .devilkin-gem {
    visibility: visible;
}

._6ftp .container img {
    width: 210px;
    height: 210px;
    object-fit: cover;
}

