._4noz {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

._4noz .scryers-cure {
    position: fixed;
    width: 100vw;
    min-height: 100vh;
    background-color: rgba(156, 156, 156, 0.15);
    display: flex;
    justify-content: center;
    z-index: 3;
}

._4noz .scryers-cure .pinnate-oft {
    font-size: 15px;
    padding: 30px;
    height: fit-content;
    background-color: white;
    margin-top: 20px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

._4noz .scryers-cure .pinnate-oft .unwedge-zed {
    font-size: 14px;
    color: rgb(90, 90, 90);
}

._4noz .scryers-cure .pinnate-oft .buffeted-yens {
    color: rgb(69, 139, 69);
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
}

._4noz .scryers-cure .avenges-lux {
    display: flex;
    gap: 10px;
    margin-top: 15px;
}

._4noz .scryers-cure .avenges-lux button {
    border: none;
    color: white;
    background-color: rgb(69, 139, 69);
    border-radius: 5px;
    padding: 6px 12px;
    cursor: pointer;
}

._4noz .scryers-cure .avenges-lux button:last-of-type {
    background-color: rgb(185, 92, 92);
}

._4noz .candours-top {
    margin: 20px;
    padding: 20px;
    min-width: 200px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

._4noz .candours-top .casked-loo .fullname {
    font-size: 14px;
    color: rgb(65, 158, 65);
    font-weight: 600;
}

._4noz .candours-top .casked-loo .reave-wean {
    font-size: 12px;
    color: rgb(90, 90, 90);
    margin-top: 5px;
}

._4noz .pains-gnat {
    position: relative;
}

._4noz .pains-gnat .escapers-loon svg {
    position: absolute;
    bottom: 5px;
    right: 100px;
    color: rgb(102, 102, 102);
    width: 28px;
    height: 28px;
    cursor: pointer;
}

._4noz .pains-gnat .escapers-loon input {
    display: none;
}
