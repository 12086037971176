._4noz {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: calc(100vh - 60px);
}

._4noz .cutback-nub {
	margin-top: 20px;
}

._4noz .comakers-gang {
	position: fixed;
	top: 0px;
	width: 100vw;
	height: 40px;
	font-size: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	background-color: green;
}

._4noz .comakers-gang.error {
	background-color: red;
}

._4noz .ironage-goer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 50vw;
	padding: 5px 0px;
	font-size: 14px;
	margin-bottom: 10px;
}

._4noz .ironage-goer button {
	color: whitesmoke;
	font-size: 13px;
	background-color: #5ccc9d;
	padding: 8px 14px;
	border-radius: 5px;
	border: none;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	margin-left: 10px;
	cursor: pointer;
}

._4noz .react-chatbot-kit-chat-container {
	width: 50vw;
	border-radius: 0px;
}

._4noz .react-chatbot-kit-chat-inner-container {
	border-radius: 0px;
}

._4noz .react-chatbot-kit-chat-header {
	border-radius: 0px;
}

._4noz .react-chatbot-kit-chat-btn-send {
	border-radius: 0px;
}

._4noz .react-chatbot-kit-chat-bot-message {
	margin-left: 0px;
	word-wrap: break-word;
}
