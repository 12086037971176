._6usd {
    display: flex;
    align-items: center;
    height: 60px;
    color: white;
    background-color: #6bbc9a;
}

._6usd .unaged-sop {
    flex: 1;
}

._6usd .pured-are {
    position: relative;
    margin-right: 25px;
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    user-select: none;
}

._6usd .pured-are .taoism-mess {
    position: absolute;
    top: 50px;
    right: 10px;
    min-width: 200px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: white;
}

._6usd .pured-are .taoism-mess ul {
    color: rgb(55, 55, 55);
    list-style: none;
    margin: 0px;
    padding: 5px;
}

._6usd .pured-are .taoism-mess ul li {
    display: flex;
    align-items: center;
    padding: 10px;
}

._6usd .pured-are .clipping-word {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 13px;
    height: 13px;
    top: 4px;
    left: 30px;
    padding: 1px;
    border-radius: 50%;
    box-sizing: border-box;
}

._6usd .pured-are .clipping-word .online-dot {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 50%;
    background-color: rgb(147, 211, 147);
}

._6usd .pured-are .clipping-word .online-dot.red {
    background-color: rgb(244, 134, 134);
}

._6usd .pured-are .profile-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 20px;
    font-weight: 800;
    color: white;
    background-color: rgb(223, 223, 223);
}

._6usd .pured-are .mycelium-melt {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: whitesmoke;
}

._6usd .manliest-aim svg {
    width: 20px;
    height: 20px;
    fill: whitesmoke;
    transform: translateY(2px);
}